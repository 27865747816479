<template>
    <master-panel>
        <template v-slot:body>
            <v-row v-if="sent">
                <v-col cols="12" class="text-center my-15">
                    <v-icon v-text="'$check_circle'" size="70" color="secondary" class="mb-3"/>
                    <div class="title">
                        Su mensaje fue enviado correctamente a <b>{{ clientName }}</b>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="mt-7 title">
                    En base a los resultados de los exámenes del
                    paciente, ingrese su respuesta a la solicitud
                    de una cx bariátrica.
                </v-col>
                <v-col cols="12" class="text-center">

                    <kimsa-textarea-field
                        class="my-3"
                        placeholder="Escriba aquí..."
                        outlined
                        rows="6"
                        @change="message = $event"
                    />

                    <v-btn color="primary" class="normal-btn" small depressed @click="sendMessage" :loading="loading">
                        Enviar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" class="normal-btn" small depressed @click="goHome">
                Dashboard
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import KimsaTextareaField from "@/components/common/inputs/KimsaTextareaField";
import MasterPanel from "@/components/layouts/MasterPanel";
import httpService from "@/services/httpService";

export default {
    name: "ExamDetailsMessage",
    components: {MasterPanel, KimsaTextareaField},
    data() {
        return {
            sent: false,
            loading: false,
            message: '',
        }
    },
    computed: {
        clientId(vm = this) {
            return vm.$route.params.id || null
        },
        clientName(vm = this) {
            return vm.client?.name || ''
        },
        status(vm = this) {
            return vm.$route.params.status || null
        },
        doctorId(vm = this) {
            return vm.currentUser?.id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClient()
    },
    methods: {
        async loadClient() {
            const vm = this

            let response = await httpService.get(`client/${vm.clientId}`)
            // console.log('response', response)
            vm.client = response.data.client
        },
        async sendMessage() {
            const vm = this
            vm.loading = true

            let response = await httpService.post(`doctor/${vm.doctorId}/clients/message`, {
                user_id: vm.clientId,
                text: vm.message,
                status: vm.status,
            }).catch(err => {
                if (err.response.data.error) vm.toast(err.response.data.message, 'error')
            })

            // console.log('sendMessage', response)

            vm.loading = false
            if (response) vm.sent = true
        }
    }
}
</script>

<style scoped>

.title {
    text-align: center;
    font-size: 17px !important;
    line-height: 21px !important;
}

</style>